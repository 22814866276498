import { FC, useMemo, useId } from 'react';

import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { useSelector } from 'src/hooks/useSelector';
import { ArticleTopic } from 'src/models/articlePage/types';
import { BlogDictionaryItem } from 'src/models/blogPage';

import ArticleTag from 'src/components/ArticleItem/Tags/Tag/Tag';

import styles from './index.less';

interface ArticleTagsProps {
    topics: BlogDictionaryItem<ArticleTopic>[];
    hhtmFromLabel?: string;
}

const ArticleTags: FC<ArticleTagsProps> = ({ topics, hhtmFromLabel }) => {
    const currentTopic = useSelector((state) => state.blogPage.topic);
    const isSmallScreen = useBreakpoint(Breakpoint.M) === Breakpoint.XS;
    const id = useId();

    const tagsToRender = useMemo(() => {
        const sorted = topics.sort((tag1, tag2) => {
            if (tag1.code === currentTopic) {
                return -1;
            }
            return tag2.code === currentTopic ? 1 : 0;
        });
        return sorted.slice(0, isSmallScreen ? 1 : 2);
    }, [currentTopic, isSmallScreen, topics]);

    return (
        <div className={styles.tags}>
            {tagsToRender.map((topic) => (
                <ArticleTag key={`${id}-${topic.code}`} code={topic.code} isTopic hhtmFromLabel={hhtmFromLabel} />
            ))}
        </div>
    );
};

export default ArticleTags;
